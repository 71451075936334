import { bindable, inject }        from 'aurelia-framework';
import { I18N }                    from 'aurelia-i18n';
import { activationStrategy }      from 'aurelia-router';
import { BaseListViewModel }       from 'base-list-view-model';
import { FilterFormSchema }        from 'modules/management/concessions/constructions/filter-form-schema';
import { ConstructionType }        from 'modules/management/models/construction-type';
import { AppContainer }            from 'resources/services/app-container';
import { Downloader }              from 'resources/services/downloader';
import { ConstructionsRepository } from './services/repository';

@inject(AppContainer, ConstructionsRepository, I18N, Downloader, FilterFormSchema)
export class ListConstructions extends BaseListViewModel {

    constructionType;
    listingId;

    @bindable headerTitle;
    @bindable newRecordRoute;
    @bindable newRecordRouteParams;
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema) {
        super(appContainer);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate(params) {
        this.constructionType = new ConstructionType(params.slug);

        return super.can([
            `management.concessions.constructions.${this.constructionType.slug}.manage`,
            `management.concessions.constructions.${this.constructionType.slug}.view`,
        ]);
    }

    /**
     * Determines activation strategy
     *
     * @return {'replace'}
     */
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.repository.setType(this.constructionType.id);

        this.listingId            = `management-specifications-concessions-constructions-${this.constructionType.slug}-listing`;
        this.headerTitle          = `listing.management.concessions.constructions.${this.constructionType.slug}`;
        this.newRecordRoute       = `management.concessions.constructions.${this.constructionType.slug}.create`;
        this.newRecordRouteParams = { type: this.constructionType };

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        const columns = [
            {
                data:  'name',
                name:  'construction_translations.name',
                title: 'form.field.designation',
            },
            {
                data:  'type',
                name:  'construction_type_translations.name',
                title: 'form.field.type',
            },
        ];

        if (this.constructionType.needsParent) {
            columns.push({
                data:  'parent_name',
                name:  'parent_construction_translations.name',
                title: 'form.field.element',
            });
        }

        this.datatable = {
            repository:      this.repository,
            show:            {
                action:  (row) => this.appContainer.router.navigateToRoute(`management.concessions.constructions.${this.constructionType.slug}.view`, { id: row.id, type: this.constructionType }),
                visible: (row) => this.appContainer.authenticatedUser.can([`management.concessions.constructions.${this.constructionType.slug}.manage`, `management.concessions.constructions.${this.constructionType.slug}.view`]),
            },
            edit:            {
                action:  (row) => this.appContainer.router.navigateToRoute(`management.concessions.constructions.${this.constructionType.slug}.edit`, { id: row.id, type: this.constructionType }),
                visible: (row) => this.appContainer.authenticatedUser.can([`management.concessions.constructions.${this.constructionType.slug}.manage`, `management.concessions.constructions.${this.constructionType.slug}.edit`]),
            },
            destroy:         {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can([`management.concessions.constructions.${this.constructionType.slug}.manage`, `management.concessions.constructions.${this.constructionType.slug}.delete`]),
            },
            options:         [
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr(`form.field.constructions.${this.constructionType.slug}`), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can([`management.concessions.constructions.${this.constructionType.slug}.manage`, `management.concessions.constructions.${this.constructionType.slug}.view`]),
                },
            ],
            selectable:      true,
            destroySelected: true,
            sorting:         {
                column:    0,
                direction: 'asc',
            },
            columns:         [
                ...columns,
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'constructions.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }
}
