import { inject }                  from 'aurelia-framework';
import { ConstructionsRepository } from 'modules/management/concessions/constructions/services/repository';
import { Construction }            from 'modules/management/models/construction';

@inject(ConstructionsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param constructionsRepository
     */
    constructor(constructionsRepository) {
        this.constructionsRepository = constructionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Construction}
     */
    model() {
        let model = new Construction();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       12,
            attributes: {
                maxlength: 255,
                readonly:  readonly,
            },
        };

        const schema = [
            [this.name],
        ];

        if (viewModel.constructionType.needsParent === 'true') {
            this.parent_id = {
                type:         'select2',
                key:          'parent_id',
                label:        'form.field.element',
                size:         12,
                remoteSource: () => this.constructionsRepository.parentByType(),
                attributes:   {
                    disabled: readonly,
                },
            };

            schema.push([this.parent_id]);
        }

        if (readonly) {
            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       6,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute({
                name:   `management.concessions.constructions.${viewModel.constructionType.slug}.index`,
                params: {
                    slug: viewModel.constructionType.slug,
                },
            }),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
